<template>
  <section class="matmed ds-container bg">
    <b-container fluid>
      <b-row class="breadcrumbs">
        <router-link to="/faturamento-tiss/dashboard" tag="span" class="path">
          Faturamento TISS
        </router-link>
        <ChevronRight class="chevron" />
        <router-link to="/faturamento-tiss/materiais-e-medicamentos" tag="span" class="path">
          Mat/Med
        </router-link>
        <ChevronRight class="chevron" />
        <span class="path">
          Tabela {{ parseTableType(type) }}
        </span>
      </b-row>
    </b-container>
    <b-container fluid class="ds-content mt-4 p-4">
      <Header 
        :type="type"
        :loading="loading"
        :simplifiedTable="simplifiedTable" 
        @updateSimplifiedTable="updateSimplifiedTable"
        @update="openImportModal"
      />
      <hr />
      <Filters 
        :type="type"
        :filters="filters"
        withExpenseCode withTable 
      />
      <Table 
        :type="type"
        :table="table" 
        :isSimplifiedTable="simplifiedTable" 
        @save="updateTableItem"
      />
      <div class="pagination-position">
        <b-pagination
          v-model="page"
          :total-rows="count"
          :per-page="limit"
          first-number
          last-number
          size="sm"
        />
      </div>
    </b-container>

    <ImportTableModal
      :isUpdate="true"
      :tableType="type"
      :tableId="tableId"
      :importErrors="importErrors"
    />
  </section>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import api from '@/modules/tiss/matMed/api'

export default {
  name: 'TablePage',
  components: {
    ChevronRight: () => import('@/assets/icons/chevron-right.svg'),
    Header: () => import('@/modules/tiss/matMed/components/tables/PageHeader'),
    Filters: () => import('@/modules/tiss/matMed/components/tables/Filters'),
    Table: () => import('@/modules/tiss/matMed/components/tables/Table.vue'),
    ImportTableModal: () => import('@/modules/tiss/matMed/components/matMed/ImportTableModal.vue'),
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      simplifiedTable: true,
      type: this.$route.params.tableType,
      table: [],
      tableId: null,
      importErrors: [],
      loading: false,
      filters: {},
      page: 1,
      count: 0,
      limit: 0,
    }
  },
  async mounted() {
    this.pusher
      .subscribe(`matmed-import-${this.clinic.id}`)
      .bind('uploadCompleted', this.realoadTable, this)
      .bind('uploadError', this.showErrors, this)

    await this.getTableData()
  },
  methods: {
    async getTableData() {
      const loading = this.$loading.show()

      try {
        this.tableId = this.$route.params.tableId

        const {
          data: {
            total,
            per_page: perPage,
            data: table,
          }
        } = await api.getImportedMaterialMedicinesTable(this.clinic.id, this.type, this.tableId, this.filters, this.page)

        this.table = table
        this.count = total
        this.limit = perPage

      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        loading.hide()
      }
    },
    async openImportModal() {
      this.importErrors = []
      this.$bvModal.show('import-table-modal')
    },
    async updateTableItem(prop) {
      try {
        const {
          effective_date: effectiveDate,
          updated_at: updatedAt
        } = prop

        const item = {
          ...prop,
          'effective_date':  effectiveDate ?? updatedAt
        }

        await api.updateMaterialMedicinesField(this.clinic.id, this.type, item.id, item)
        this.$toast.success('Item atualizado com sucesso!')
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    updateSimplifiedTable(simplified) {
      this.simplifiedTable = simplified
    },
    parseTableType(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    async realoadTable(data) {
      const hasResults = this.table.find(item => item.table_type === this.type)

      if (!hasResults) {
        await this.getTableData()
        this.$bvModal.hide('import-table-modal')
      }
    },
    async showErrors(data) {
      this.importErrors = data
    },
  },
  watch: {
    filters: {
      async handler() {
        await this.getTableData()
      },
      deep: true,
    },
    page: {
      async handler() {
        await this.getTableData()
      },
    },
  }
}
</script>

<style lang="scss" src="@/modules/tiss/matMed/styles/tiss.scss" />
<style lang="scss" scoped>
  .matmed {
    overflow-x: scroll;
  }
</style>
